<template>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <button className="col-3 float-end btn btn-success" v-on:click="create">Create Builder</button>
      </div>
    </div>
  </div>
  <DataTable2
      :headers="headers"
      :actions="actions"
      :api="getServers"
      :trigger="trigger"
      :fields="['name']"
  />
</template>

<script>
import DataTable2 from 'data-table/src/components/DataTable2'
import {api} from "../services/api";
import InsertModalService from "modal/src/services/InsertModal";
import ModalService from "modal/src/services/Modal";
import toastService from "toast/src/services/toast";

export default {
  name: "BuilderSettings",
  components: {DataTable2},
  methods: {
    refresh() {
      if (this.trigger.refresh != null)
        this.trigger.refresh();
    },
    async upsert(server, message) {
      await api.builders.post("", server);
      if (!server._id) {
        this.refresh();
      }
      toastService.Notify({
        title: "Success",
        message: `You've successfully ${message} an item.`,
        imgSrc: "/egc.png"
      })
    },
    getServers(filter, skip, limit) {
      return api.builders.post("get", {
        filter, skip, limit
      });
    },
    create() {
      InsertModalService.Show({
        title: `Create Server`,
        type: this.type,
        model: {}
      }, (Server) => {
        this.upsert(Server, "create")
      });
    },
    edit(server) {
      InsertModalService.Show({
        title: `Edit Server`,
        type: this.type,
        model: server
      }, (entity) => {
        this.upsert(entity, "edit");
      });
    },

    remove(server) {
      ModalService.Show({
        title: `"Delete Server`,
        message: `Do you really want to delete ${server.name}?`,
      }, async () => {
        await api.builders.delete(server._id);
        this.refresh();
        toastService.Notify({
          title: "Success",
          message: "You've successfully delete an item.",
          imgSrc: "/egc.png"
        })
      });
    }
  },
  data() {
    return {
      trigger: {},
      type: {name: "name", hostname: "text", username: "username", directory: "directory", pem: "text"},
      headers: {
        "_id": "#",
        "name": "Name",
        "hostname": "Host Name",
        "username": "User",
        "pem": "Pem"
      },
      actions: [
        {
          classNames: "fa fa-pen text-success",
          callback: this.edit
        },
        {
          classNames: "fa fa-trash text-danger",
          callback: this.remove
        }
      ]
    }
  }
}
</script>